import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query GetAboutPortrait {
      file(relativePath: { eq: "drysso2020_450x630.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About Us" />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-full is-8-widescreen">
              <h1 className="title is-size-1">About Us</h1>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-third">
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt="Deborah Rysso"
              />
            </div>
            <div className="column is-two-thirds">
              <h2 className="title is-2 is-size-3-mobile">
                Deborah Rysso has practiced elder law since moving to Traverse
                City in 2004 and is a partner at Rysso Law, PLLC.
              </h2>
              <p>
                In 2011, 2012, 2013, and 2014, Deborah was named a “Rising Star”
                by Michigan Super Lawyers. Deborah was voted one of the 20
                Region’s Leading Lawyers in the Traverse City Business News in
                December 2013.
              </p>
              <p>
                Deborah is active in the Traverse City community; she writes a
                quarterly column in the Traverse City Record Eagle with legal
                topics for baby boomers, served as the 2012-2013 president of
                the Grand Traverse, Leelanau, Antrim Bar Association, served for
                several years as president of the local Women Lawyer’s
                Association, is a member of the Bay Area Senior Advocates, and
                has taught as an adjunct professor at Northwestern Michigan
                College.
              </p>
              <p>
                She has also completed the arduous ICLE Probate and Estate
                Planning Certificate Program. Her law degree is from Thomas M.
                Cooley Law School and her undergraduate degree is from the
                University of Michigan.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutUs
